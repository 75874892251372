import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AdminDashboard() {
  const [cars, setCars] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState({});
  const [newCar, setNewCar] = useState({
    brand: '', model: '', year: '', price: '', mileage: '', fuel: '',
    options: [], description: '', image: null
  });
  const [editingCar, setEditingCar] = useState(null);
  const [showNewBrandInput, setShowNewBrandInput] = useState(false);
  const [showNewModelInput, setShowNewModelInput] = useState(false);
  const [newBrand, setNewBrand] = useState('');
  const [newModel, setNewModel] = useState('');

  useEffect(() => {
    // Charger toutes les voitures existantes, y compris celles de la page d'accueil
    const savedCars = JSON.parse(localStorage.getItem('cars')) || [];
    const homepageCars = JSON.parse(localStorage.getItem('homepageCars')) || [];
    const allCars = [...savedCars, ...homepageCars];
    setCars(allCars);

    // Charger les marques et modèles existants
    const savedBrands = JSON.parse(localStorage.getItem('brands')) || [];
    const savedModels = JSON.parse(localStorage.getItem('models')) || {};
    setBrands(savedBrands);
    setModels(savedModels);

    // Si aucune marque n'existe, initialiser avec les marques des voitures existantes
    if (savedBrands.length === 0) {
      const uniqueBrands = [...new Set(savedCars.map(car => car.brand))];
      setBrands(uniqueBrands);
      localStorage.setItem('brands', JSON.stringify(uniqueBrands));
    }

    // Si aucun modèle n'existe, initialiser avec les modèles des voitures existantes
    if (Object.keys(savedModels).length === 0) {
      const newModels = savedCars.reduce((acc, car) => {
        if (!acc[car.brand]) {
          acc[car.brand] = [];
        }
        if (!acc[car.brand].includes(car.model)) {
          acc[car.brand].push(car.model);
        }
        return acc;
      }, {});
      setModels(newModels);
      localStorage.setItem('models', JSON.stringify(newModels));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cars', JSON.stringify(cars));
    localStorage.setItem('brands', JSON.stringify(brands));
    localStorage.setItem('models', JSON.stringify(models));
  }, [cars, brands, models]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingCar) {
      setEditingCar({ ...editingCar, [name]: value });
    } else {
      setNewCar({ ...newCar, [name]: value });
    }
  };

  const handleBrandChange = (e) => {
    const brand = e.target.value;
    if (editingCar) {
      setEditingCar({ ...editingCar, brand, model: '' });
    } else {
      setNewCar({ ...newCar, brand, model: '' });
    }
  };

  const handleAddBrand = () => {
    if (newBrand && !brands.includes(newBrand)) {
      setBrands([...brands, newBrand]);
      setNewBrand('');
      setShowNewBrandInput(false);
    }
  };

  const handleAddModel = () => {
    const brand = editingCar ? editingCar.brand : newCar.brand;
    if (newModel && brand && !models[brand]?.includes(newModel)) {
      setModels({
        ...models,
        [brand]: [...(models[brand] || []), newModel]
      });
      setNewModel('');
      setShowNewModelInput(false);
    }
  };

  const handleOptionChange = (e) => {
    const options = e.target.value.split(',').map(option => option.trim()).filter(option => option !== '');
    if (editingCar) {
      setEditingCar({ ...editingCar, options });
    } else {
      setNewCar({ ...newCar, options });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (editingCar) {
          setEditingCar({ ...editingCar, image: reader.result });
        } else {
          setNewCar({ ...newCar, image: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddCar = (e) => {
    e.preventDefault();
    const newCarData = editingCar ? editingCar : { ...newCar, id: Date.now() };
    
    const updatedCars = editingCar 
      ? cars.map(car => car.id === editingCar.id ? newCarData : car)
      : [...cars, newCarData];

    setCars(updatedCars);
    localStorage.setItem('cars', JSON.stringify(updatedCars));

    setEditingCar(null);
    setNewCar({
      brand: '', model: '', year: '', price: '', mileage: '', fuel: '',
      options: [], description: '', image: null
    });
  };

  const handleEditCar = (car) => {
    setEditingCar(car);
    setNewCar(car);
  };

  const handleDeleteCar = (id) => {
    setCars(cars.filter(car => car.id !== id));
  };

  const cancelEdit = () => {
    setEditingCar(null);
    setNewCar({
      brand: '', model: '', year: '', price: '', mileage: '', fuel: '',
      options: [], description: '', image: null
    });
  };

  return (
    <div className="admin-dashboard">
      <h1>Tableau de bord Administrateur</h1>
      
      <div className="admin-form">
        <h2>{editingCar ? 'Modifier une annonce' : 'Ajouter une nouvelle annonce'}</h2>
        <form onSubmit={handleAddCar} className="add-car-form">
          <div className="form-row">
            <div className="form-group">
              <label>Marque</label>
              <div className="select-wrapper">
                <select
                  name="brand"
                  value={editingCar ? editingCar.brand : newCar.brand}
                  onChange={handleBrandChange}
                  required
                >
                  <option value="">Sélectionnez une marque</option>
                  {brands.map(brand => (
                    <option key={brand} value={brand}>{brand}</option>
                  ))}
                </select>
                <button type="button" onClick={() => setShowNewBrandInput(true)} className="add-button">+</button>
              </div>
              {showNewBrandInput && (
                <div className="new-input-wrapper">
                  <input
                    type="text"
                    value={newBrand}
                    onChange={(e) => setNewBrand(e.target.value)}
                    placeholder="Nouvelle marque"
                  />
                  <button type="button" onClick={handleAddBrand}>Ajouter</button>
                </div>
              )}
            </div>
            <div className="form-group">
              <label>Modèle</label>
              <div className="select-wrapper">
                <select
                  name="model"
                  value={editingCar ? editingCar.model : newCar.model}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Sélectionnez un modèle</option>
                  {models[editingCar ? editingCar.brand : newCar.brand]?.map(model => (
                    <option key={model} value={model}>{model}</option>
                  ))}
                </select>
                <button type="button" onClick={() => setShowNewModelInput(true)} className="add-button">+</button>
              </div>
              {showNewModelInput && (
                <div className="new-input-wrapper">
                  <input
                    type="text"
                    value={newModel}
                    onChange={(e) => setNewModel(e.target.value)}
                    placeholder="Nouveau modèle"
                  />
                  <button type="button" onClick={handleAddModel}>Ajouter</button>
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Année</label>
              <input
                type="number"
                name="year"
                placeholder="Année"
                value={editingCar ? editingCar.year : newCar.year}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Prix</label>
              <input
                type="number"
                name="price"
                placeholder="Prix"
                value={editingCar ? editingCar.price : newCar.price}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Kilométrage</label>
              <input
                type="number"
                name="mileage"
                placeholder="Kilométrage"
                value={editingCar ? editingCar.mileage : newCar.mileage}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Carburant</label>
              <select
                name="fuel"
                value={editingCar ? editingCar.fuel : newCar.fuel}
                onChange={handleInputChange}
                required
              >
                <option value="">Sélectionnez le carburant</option>
                <option value="Essence">Essence</option>
                <option value="Diesel">Diesel</option>
                <option value="Électrique">Électrique</option>
                <option value="Hybride">Hybride</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>Options</label>
            <input
              type="text"
              name="options"
              placeholder="Options (séparées par des virgules)"
              value={(editingCar ? editingCar.options : newCar.options).join(', ')}
              onChange={handleOptionChange}
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              placeholder="Description du véhicule"
              value={editingCar ? editingCar.description : newCar.description}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label>Photo du véhicule</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
            {(editingCar?.image || newCar.image) && (
              <div className="image-preview-container">
                <img src={editingCar?.image || newCar.image} alt="Aperçu" className="image-preview" />
              </div>
            )}
          </div>
          <div className="form-actions">
            <button type="submit" className="submit-button">
              {editingCar ? 'Modifier' : 'Ajouter'}
            </button>
            {editingCar && (
              <button type="button" onClick={cancelEdit} className="cancel-button">
                Annuler
              </button>
            )}
          </div>
        </form>
      </div>

      <div className="existing-cars">
        <h2>Annonces existantes</h2>
        {cars.length === 0 ? (
          <p>Aucune annonce existante.</p>
        ) : (
          cars.map(car => (
            <div key={car.id} className="existing-car-item">
              <div className="car-info">
                <Link to={`/car/${car.id}`} className="car-link">
                  <h3>{car.brand} {car.model}</h3>
                  {car.image ? (
                    <div className="car-image-container">
                      <img src={car.image} alt={`${car.brand} ${car.model}`} className="car-thumbnail" />
                    </div>
                  ) : (
                    <div className="car-image-placeholder">Image non disponible</div>
                  )}
                </Link>
                <p>Année : {car.year}, Prix : {car.price}€</p>
                <p>Kilométrage : {car.mileage} km, Carburant : {car.fuel}</p>
                <p>Options : {car.options ? car.options.join(', ') : 'Aucune option'}</p>
                <p>Description : {car.description || 'Aucune description'}</p>
              </div>
              <div className="car-actions">
                <button className="edit-button" onClick={() => handleEditCar(car)}>Modifier</button>
                <button className="delete-button" onClick={() => handleDeleteCar(car.id)}>Supprimer</button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;