import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

function CarDetails() {
  const [car, setCar] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const savedCars = JSON.parse(localStorage.getItem('cars')) || [];
    const foundCar = savedCars.find(car => car.id === parseInt(id));
    setCar(foundCar);
  }, [id]);

  if (!car) {
    return <div>Voiture non trouvée</div>;
  }

  return (
    <div className="car-details">
      <h2>{car.brand} {car.model}</h2>
      {car.image && <img src={car.image} alt={`${car.brand} ${car.model}`} />}
      <p>Année : {car.year}</p>
      <p>Prix : {car.price} €</p>
      <p>Kilométrage : {car.mileage} km</p>
      <p>Carburant : {car.fuel}</p>
      <p>Options : {car.options.join(', ')}</p>
      <p>Description : {car.description}</p>
      <Link to="/">Retour à la liste</Link>
    </div>
  );
}

export default CarDetails;