import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header({ isLoggedIn, onLogout }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setShowWelcome(true);
      const timer = setTimeout(() => {
        setShowWelcome(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isLoggedIn]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div className="logo-container">
        <Link to="/" className="logo">KAD AUTO</Link>
        <span className="logo-subtitle">Votre partenaire automobile de confiance</span>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        Menu
      </button>
      <nav className={menuOpen ? 'open' : ''}>
        <ul>
          <li><Link to="/" onClick={toggleMenu}>Accueil</Link></li>
          <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
          <li><Link to="/location" onClick={toggleMenu}>Où nous trouver</Link></li>
          {isLoggedIn ? (
            <>
              <li><Link to="/admin" onClick={toggleMenu}>Admin</Link></li>
              <li><button onClick={() => { onLogout(); toggleMenu(); }} className="logout-button">Déconnexion</button></li>
            </>
          ) : (
            <li><Link to="/login" onClick={toggleMenu}>Connexion</Link></li>
          )}
        </ul>
      </nav>
      {showWelcome && (
        <div className="welcome-message">
          Bienvenue, Karim !
        </div>
      )}
    </header>
  );
}

export default Header;