import React from 'react';

function Location() {
  return (
    <div className="location-container">
      <h1>Où nous trouver</h1>
      <div className="location-content">
        <div className="location-info">
          <h2>Nos coordonnées</h2>
          <p><i className="fas fa-map-marker-alt"></i> 123 Rue de l'Automobile, 57100 Thionville</p>
          <p><i className="fas fa-phone"></i> 03 82 12 34 56</p>
          <p><i className="fas fa-envelope"></i> contact@kadauto.fr</p>
          <div className="location-hours">
            <h3>Heures d'ouverture</h3>
            <p><i className="far fa-clock"></i> Lundi - Vendredi : 9h00 - 18h00</p>
            <p><i className="far fa-clock"></i> Samedi : 10h00 - 16h00</p>
            <p><i className="far fa-clock"></i> Dimanche : Fermé</p>
          </div>
        </div>
        <div className="location-map">
          <iframe
            title="KAD AUTO Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2597.4812620307927!2d6.157645776890452!3d49.35775737139307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4795226148ebc361%3A0x4c91934128544df3!2sThionville!5e0!3m2!1sfr!2sfr!4v1682426143330!5m2!1sfr!2sfr"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Location;