import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CarList() {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [filters, setFilters] = useState({
    brand: '',
    model: '',
    minPrice: '',
    maxPrice: '',
    minYear: '',
    maxYear: '',
    minMileage: '',
    maxMileage: '',
    fuel: '',
    transmission: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const loadCars = () => {
      const savedCars = JSON.parse(localStorage.getItem('cars')) || [];
      setCars(savedCars);
      setFilteredCars(savedCars);
    };

    loadCars();

    // Écouter les changements dans le localStorage
    window.addEventListener('storage', loadCars);

    return () => {
      window.removeEventListener('storage', loadCars);
    };
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const applyFilters = () => {
    let filtered = cars;

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(car => 
        car.brand.toLowerCase().includes(searchLower) ||
        car.model.toLowerCase().includes(searchLower)
      );
    }

    if (filters.brand) filtered = filtered.filter(car => car.brand === filters.brand);
    if (filters.model) filtered = filtered.filter(car => car.model === filters.model);
    if (filters.minPrice) filtered = filtered.filter(car => car.price >= parseInt(filters.minPrice));
    if (filters.maxPrice) filtered = filtered.filter(car => car.price <= parseInt(filters.maxPrice));
    if (filters.minYear) filtered = filtered.filter(car => car.year >= parseInt(filters.minYear));
    if (filters.maxYear) filtered = filtered.filter(car => car.year <= parseInt(filters.maxYear));
    if (filters.minMileage) filtered = filtered.filter(car => car.mileage >= parseInt(filters.minMileage));
    if (filters.maxMileage) filtered = filtered.filter(car => car.mileage <= parseInt(filters.maxMileage));
    if (filters.fuel) filtered = filtered.filter(car => car.fuel === filters.fuel);
    if (filters.transmission) filtered = filtered.filter(car => car.transmission === filters.transmission);

    setFilteredCars(filtered);
  };

  const resetFilters = () => {
    setFilters({
      brand: '',
      model: '',
      minPrice: '',
      maxPrice: '',
      minYear: '',
      maxYear: '',
      minMileage: '',
      maxMileage: '',
      fuel: '',
      transmission: ''
    });
    setSearchTerm('');
    setFilteredCars(cars);
  };

  useEffect(() => {
    applyFilters();
  }, [searchTerm, filters]);

  return (
    <div className="car-list-container">
      <div className="search-section">
        <h1>Rechercher un véhicule</h1>
        <div className="search-bar">
          <input 
            type="text" 
            placeholder="Rechercher une marque, un modèle..." 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="search-button" onClick={applyFilters}>Rechercher</button>
        </div>
        <button className="filter-toggle" onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? 'Masquer les filtres' : 'Afficher les filtres'}
        </button>
      </div>
      
      {showFilters && (
        <div className="filters">
          <div className="filter-row">
            <div className="filter-group">
              <label>Marque</label>
              <select name="brand" value={filters.brand} onChange={handleFilterChange}>
                <option value="">Toutes les marques</option>
                {Array.from(new Set(cars.map(car => car.brand))).map(brand => (
                  <option key={brand} value={brand}>{brand}</option>
                ))}
              </select>
            </div>
            <div className="filter-group">
              <label>Modèle</label>
              <select name="model" value={filters.model} onChange={handleFilterChange}>
                <option value="">Tous les modèles</option>
                {Array.from(new Set(cars.filter(car => !filters.brand || car.brand === filters.brand).map(car => car.model))).map(model => (
                  <option key={model} value={model}>{model}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="filter-row">
            <div className="filter-group">
              <label>Prix</label>
              <div className="range-inputs">
                <input type="number" name="minPrice" placeholder="Min" value={filters.minPrice} onChange={handleFilterChange} />
                <input type="number" name="maxPrice" placeholder="Max" value={filters.maxPrice} onChange={handleFilterChange} />
              </div>
            </div>
            <div className="filter-group">
              <label>Année</label>
              <div className="range-inputs">
                <input type="number" name="minYear" placeholder="Min" value={filters.minYear} onChange={handleFilterChange} />
                <input type="number" name="maxYear" placeholder="Max" value={filters.maxYear} onChange={handleFilterChange} />
              </div>
            </div>
          </div>
          
          <button className="more-filters-toggle" onClick={() => setShowMoreFilters(!showMoreFilters)}>
            {showMoreFilters ? '- Moins de filtres' : '+ Plus de filtres'}
          </button>
          
          {showMoreFilters && (
            <div className="additional-filters">
              <div className="filter-row">
                <div className="filter-group">
                  <label>Kilométrage</label>
                  <div className="range-inputs">
                    <input type="number" name="minMileage" placeholder="Min" value={filters.minMileage} onChange={handleFilterChange} />
                    <input type="number" name="maxMileage" placeholder="Max" value={filters.maxMileage} onChange={handleFilterChange} />
                  </div>
                </div>
                <div className="filter-group">
                  <label>Carburant</label>
                  <select name="fuel" value={filters.fuel} onChange={handleFilterChange}>
                    <option value="">Tous les carburants</option>
                    <option value="Essence">Essence</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Électrique">Électrique</option>
                    <option value="Hybride">Hybride</option>
                  </select>
                </div>
                <div className="filter-group">
                  <label>Transmission</label>
                  <select name="transmission" value={filters.transmission} onChange={handleFilterChange}>
                    <option value="">Toutes les transmissions</option>
                    <option value="Manuelle">Manuelle</option>
                    <option value="Automatique">Automatique</option>
                  </select>
                </div>
              </div>
            </div>
          )}
          
          <div className="filter-actions">
            <button onClick={resetFilters} className="reset-filters">Réinitialiser les filtres</button>
          </div>
        </div>
      )}
      
      <div className="car-list">
        {filteredCars.map(car => (
          <div key={car.id} className="car-card">
            <div className="car-image-container">
              {car.image ? (
                <img src={car.image} alt={`${car.brand} ${car.model}`} className="car-image" />
              ) : (
                <div className="car-image-placeholder">Image non disponible</div>
              )}
            </div>
            <div className="car-info">
              <h3>{car.brand} {car.model}</h3>
              <p>Année : {car.year}</p>
              <p>Kilométrage : {car.mileage} km</p>
              <p>Carburant : {car.fuel}</p>
              <p>Transmission : {car.transmission}</p>
              <p className="car-price">{car.price} €</p>
              <Link to={`/car/${car.id}`} className="view-details">Voir les détails</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarList;