import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Formulaire soumis:', formData);
    setFormData({ name: '', email: '', subject: '', message: '' });
    alert('Votre message a été envoyé. Nous vous contacterons bientôt !');
  };

  return (
    <div className="contact-container">
      <h1>Contactez-nous</h1>
      <div className="contact-content">
        <div className="contact-info">
          <h2>Nos coordonnées</h2>
          <p><i className="fas fa-map-marker-alt"></i> 123 Rue de l'Automobile, 57100 Thionville</p>
          <p><i className="fas fa-phone"></i> 03 82 12 34 56</p>
          <p><i className="fas fa-envelope"></i> contact@kadauto.fr</p>
          <div className="contact-hours">
            <h3>Heures d'ouverture</h3>
            <p><i className="far fa-clock"></i> Lundi - Vendredi: 9h00 - 18h00</p>
            <p><i className="far fa-clock"></i> Samedi: 10h00 - 16h00</p>
            <p><i className="far fa-clock"></i> Dimanche: Fermé</p>
          </div>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2>Envoyez-nous un message</h2>
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Sujet</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button">Envoyer</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;