import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <p>&copy; 2023 KAD AUTO. Tous droits réservés.</p>
      <Link to="/admin-login" className="admin-link">Admin</Link>
    </footer>
  );
}

export default Footer;